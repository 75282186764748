export default [
  {
    path: '',
    name: 'apps-engine-list',
    component: () => import(/* webpackChunkName: "apps-engine-list" */ '@/apps/engine/pages/EngineFormPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: ':id',
    name: 'apps-form-get',
    component: () => import(/* webpackChunkName: "apps-form-get" */ '@/apps/engine/pages/EngineFormPage.vue'),
    meta: {
      layout: 'content',
    },
  },
]
