<template>
  <div>
    <v-sidebar-menu />
    <v-main>
      <v-container
        class="mt-10"
        fluid
      >
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import VSidebarMenu from '@/components/SidebarMenu.vue' // Adjust path if needed

export default {
  components: {
    VSidebarMenu,
  },
}
</script>

<style scoped>
  .v-main {
    padding-left: 50px !important; /* Reset the padding-left */
  }
</style>
