<template>
  <v-navigation-drawer
    app
    permanent
    expand-on-hover
    dark
    color="primary"
    :mini-variant.sync="mini"
  >
    <v-list>
      <!--
      <v-list-item
        :class="mini ? 'px-2 ' : 'px-2'"
      >
        <v-img
          v-if="mini"
          src="@/assets/images/logsmart/logo.png"
          min-width="40px"
        />
        <v-img
          v-else
          src="@/assets/images/logsmart/logo_horizontal1.png"
          max-height="90px"
          contain
        />
      </v-list-item>
      -->
      <!--
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Sandra lee
          </v-list-item-title>
          <v-list-item-subtitle>sandra_a88@gmail.com</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      -->
    </v-list>


    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="userData.avatar"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ userData.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ userData.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
      nav
      dense
    >
      <v-list-item
        v-for="(item, i) in navMenuItems"
        :key="i"
        link
        :to="{ name: item.to }"
      >
        <v-list-item-icon>
          <v-icon size="16">
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list
        dense
      >
        <v-list-item
          link
          :to="{ name: 'apps-auth-logout' }"
        >
          <v-list-item-icon>
            <v-icon size="16">
              mdi-logout
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      mini: true,
      navMenuItems: [],
      userData: {
        name: null,
        email: null,
        avatar: null,
      }
    }
  },
  setup() { },
  created() {
    this.getUserData()
    this.getUserMenu()
  },
  methods: {
    getUserMenu() {
      const icons = {
        /*
        'apps-dashboard-admin': 'mdi-view-dashboard',
        'apps-document': 'mdi-check-all',
        'apps-auction': 'mdi-gavel',
        'apps-payment': 'mdi-cash',
        'apps-order': 'mdi-truck',
        'apps-user': 'mdi-account-multiple',
        'apps-map': 'mdi-map-search',
        'apps-settings': 'mdi-cog',

        'apps-accreditation-list': 'mdi-check-all',

        'apps-dashboard-customer': 'mdi-view-dashboard',
        'apps-order-selector': 'mdi-plus',
        'apps-order-list': 'mdi-truck',

        'apps-dashboard-carrier': 'mdi-view-dashboard',
        'apps-carrier-list': 'mdi-truck',
        'apps-auction-list': 'mdi-gavel',
        'apps-bid-list': 'mdi-update',
        'apps-transport-list': 'mdi-truck',
        'apps-track-list': 'mdi-map-marker-distance',

        'apps-dashboard-driver': 'mdi-view-dashboard',
        */
      'apps-project-list': 'mdi-briefcase',
      'apps-case-list': 'mdi-human-child'
      }

      const json = localStorage.getItem('userMenu')
      
      if (json) {
        const userMenu = JSON.parse(json)

        userMenu.forEach(x => {
          this.navMenuItems.push({
            title: x.title,
            icon: icons[x.to],
            to: x.to,
          })
        })
      } else {
        console.error('error userMenu')
      }
    },
    getUserData() {
      const json = localStorage.getItem('userData')
      
      if (json) {
        const userData = JSON.parse(json)

        this.userData.name = userData.name
        this.userData.email = userData.email
        this.userData.avatar = userData.avatar
      } else {
        console.error('error userMenu')
      }
    },
  },
}
</script>

<style scoped>
</style>
