export default {
  setItems: (state, data) => {
    state.items.splice(0)
    
    const colors = {
      Archivado: 'secondary',
      Abierto: 'success',
      Cerrado: 'secondary',
    }

    // eslint-disable-next-line array-callback-return
    data.map(x => {
      state.items.push({
        id: x.id,
        type: x.type,
        code: x.code,
        name: x.name,
        manager: x.manager,
        status: x.status,
        color: colors[x.status],
        start: x.start,
        end: x.end,
        address: x.address,
        progress: x.progress,
        description: x.description,
        organization: x.organization
      })
    })
  },
  setManagers: (state, data) => {
    state.managers.splice(0)
    
    // eslint-disable-next-line array-callback-return
    data.map(x => {
      state.managers.push({
        id: x.id,
        avatar: x.avatar,
        text: x.name,
      })
    })
  },
}
