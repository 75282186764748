import axios from 'axios'

export default {
  create: (context, payload) => {
    const { commit } = context;

    commit("setForm", payload);
    commit("setEditable", payload);

    console.log(payload)
  },
  edit: (context, payload) => {
    const { commit } = context;

    commit("setValue", payload);
  },
  add: (context, payload) => {
    const { commit } = context;

    commit("addRow", payload);
  },
  remove: (context, payload) => {
    const { commit } = context;

    commit("removeRow", payload);
  },
  // eslint-disable-next-line no-unused-vars
  get: (context, payload) => {
    const { commit } = context
    const { id } = payload
    const url = `https://catim-snapshot.pages.dev/forms/${id}.json`
    //const url = `http://localhost:8080/forms/${id}.json`

    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          const { fields } = response.data

          commit('setItem', response.data)
          commit("setEditable", fields)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
