import { render, staticRenderFns } from "./HorizontalLayout.vue?vue&type=template&id=9b289564&scoped=true"
import script from "./HorizontalLayout.vue?vue&type=script&lang=js"
export * from "./HorizontalLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b289564",
  null
  
)

export default component.exports