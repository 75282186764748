export default [
  {
    path: '',
    name: 'apps-project-list',
    component: () => import(/* webpackChunkName: "apps-project-list" */ '@/apps/project/pages/ProjectListPage.vue'),
    meta: {
      layout: 'content',
    },
  },
]
