//import axios from 'axios'

export default {
  // eslint-disable-next-line no-unused-vars
  create: (context, payload) => {
    return new Promise((resolve) => {
      resolve()
    })
    /*
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          // commit('setItem', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  edit: (context, payload) => {
    return new Promise((resolve) => {
      resolve()
    })
    /*
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          // commit('setItem', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  list: (context, payload) => {
    const { commit } = context

    const rows = [
      { id: '0', name: 'Acta de traspaso', status: 'complete', stage: 'stage1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '1', name: 'Ficha de ingreso', status: 'complete', stage: 'stage1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '4', name: 'Entrevista acogida', status: 'complete', stage: 'stage1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '5', name: 'Validación etapa', status: 'complete', stage: 'stage1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    
      { id: '7', name: 'Sesiones terapéuticas NNA y familia', status: 'incomplete', stage: 'stage2', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '8', name: 'Verificación de redes informadas', status: 'assigned', stage: 'stage2', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '9', name: 'Acta sesión', status: 'assigned', stage: 'stage2', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '10', name: 'Sesión revisión y ajuste del PII-U', status: 'assigned', stage: 'stage2', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    
      { id: '11', name: 'Sesiones terapéuticas', status: 'assigned', stage: 'stage3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '12', name: 'Ejecución talleres', status: 'assigned', stage: 'stage3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '14', name: 'Pauta evaluación', status: 'assigned', stage: 'stage3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '15', name: 'Validación para pasar a sostenibilidad', status: 'assigned', stage: 'stage3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    
      { id: '16', name: 'Sesiones sostenibilidad', status: 'assigned', stage: 'stage4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '17', name: 'Acciones de monitoreo a nivel socio comunitario', status: 'assigned', stage: 'stage4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '18', name: 'Sesión para evaluar uso de redes', status: 'assigned', stage: 'stage4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '20', name: 'Sesión de evaluación de PII-U', status: 'assigned', stage: 'stage4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '21', name: 'Sesiones de desvinculación progresiva', status: 'assigned', stage: 'stage4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    
      { id: '22', name: 'Ceremonia de cierre', status: 'assigned', stage: 'stage5', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: '23', name: 'Encuesta satisfacción', status: 'assigned', stage: 'stage5', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    ];

    return new Promise((resolve) => {
      commit('setItems', rows)
      resolve(rows)
    })

    /*
    const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          const { rows, resume } = response.data

          commit('setItems', rows)
          commit('setResume', resume)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
}
