import axios from 'axios'

export default {
  register: (context, payload) => {
    const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/auth/register`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          commit('setUser', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // eslint-disable-next-line no-unused-vars
  login: (context, payload) => {
    const { commit } = context
    const data = {
      success: true,
      accessToken: 'xxxx',
      userMenu: [
        { title: 'Proyectos', to: 'apps-project-list' },
        { title: 'Registro inicial', to: 'apps-case-list' }
      ],
      userData: {
        name: 'John Denver',
        email: 'john.denver@gmail.com',
        avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460'
      }
    }

    return new Promise((resolve) => {
      commit('setUser', data)
      resolve(data)
    })
    /*
    const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/auth/login`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          commit('setUser', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
        */
  },
  recovery: (_context, payload) => {
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/auth/recovery`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
