//import axios from 'axios'

export default {
  // eslint-disable-next-line no-unused-vars
  create: (context, payload) => {
    return new Promise((resolve) => {
      resolve()
    })
    /*
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          // commit('setItem', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  edit: (context, payload) => {
    return new Promise((resolve) => {
      resolve()
    })
    /*
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          // commit('setItem', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  list: (context, payload) => {
    const { commit } = context

    const rows = [
      { id: 0, dni: '96458182', name: 'Juan Pérez', gender: 'Femenino', nationality: 'Chilena', contact: '987654321', address: 'Calle Las Flores 456, Concepción', stage: 'stage1' },
      { id: 1, dni: '87011826', name: 'Carlos Gómez', gender: 'Masculino', nationality: 'Chilena', contact: '765432109', address: 'Calle El Roble 123, Antofagasta', stage: 'stage2' },
      { id: 2, dni: '06528617', name: 'Sebastián Reyes', gender: 'Femenino', nationality: 'Chileno', contact: '876543210', address: 'Calle El Roble 123, Antofagasta', stage: 'stage3' },
      { id: 3, dni: '12313069', name: 'Francisco Herrera', gender: 'Masculino', nationality: 'Chileno', contact: '876543210', address: 'Calle Las Flores 456, Concepción', stage: 'stage0' },
      { id: 4, dni: '50525331', name: 'Claudia Rojas', gender: 'Masculino', nationality: 'Chileno', contact: '987654321', address: 'Av. Providencia 789, Santiago', stage: 'stage5' },
      { id: 5, dni: '59474335', name: 'Martina Salazar', gender: 'Femenino', nationality: 'Chilena', contact: '234567890', address: 'Calle Las Flores 456, Concepción', stage: 'stage1' },
      { id: 6, dni: '02008083', name: 'Catalina Correa', gender: 'Masculino', nationality: 'Chilena', contact: '234567890', address: 'Paseo Ahumada 123, Santiago', stage: 'stage2' },
      { id: 7, dni: '51309211', name: 'Diego Torres', gender: 'Masculino', nationality: 'Chilena', contact: '234567890', address: 'Calle Las Flores 456, Concepción', stage: 'stage3' },
      { id: 8, dni: '75361383', name: 'Carlos Gómez', gender: 'Masculino', nationality: 'Chileno', contact: '234567890', address: 'Av. Providencia 789, Santiago', stage: 'stage4' },
      { id: 9, dni: '55527388', name: 'Felipe Soto', gender: 'Femenino', nationality: 'Chileno', contact: '987654321', address: 'Calle El Roble 123, Antofagasta', stage: 'stage5' },
      { id: 10, dni: '24278188', name: 'Felipe Soto', gender: 'Femenino', nationality: 'Chileno', contact: '876543210', address: 'Av. Providencia 789, Santiago', stage: 'stage0' },
      { id: 11, dni: '63188225', name: 'Martina Salazar', gender: 'Femenino', nationality: 'Chileno', contact: '876543210', address: 'Calle Las Flores 456, Concepción', stage: 'stage2' },
      { id: 12, dni: '65603053', name: 'Gabriela Morales', gender: 'Masculino', nationality: 'Chilena', contact: '987654321', address: 'Paseo Ahumada 123, Santiago', stage: 'stage3' },
      { id: 13, dni: '21456015', name: 'Claudia Rojas', gender: 'Masculino', nationality: 'Chilena', contact: '987654321', address: 'Av. Libertador 1234, Santiago', stage: 'stage4' },
      { id: 14, dni: '13302395', name: 'Francisco Herrera', gender: 'Masculino', nationality: 'Chileno', contact: '123456789', address: 'Paseo Ahumada 123, Santiago', stage: 'stage5' },
      { id: 15, dni: '90070530', name: 'Sofía Castro', gender: 'Femenino', nationality: 'Chileno', contact: '234567890', address: 'Calle Las Flores 456, Concepción', stage: 'stage1' },
      { id: 16, dni: '78314890', name: 'Gabriela Morales', gender: 'Femenino', nationality: 'Chileno', contact: '345678901', address: 'Calle Los Alerces 5678, Valparaíso', stage: 'stage2' },
      { id: 17, dni: '73594966', name: 'Carlos Gómez', gender: 'Femenino', nationality: 'Chilena', contact: '987654321', address: 'Av. Providencia 789, Santiago', stage: 'stage3' },
      { id: 18, dni: '11568630', name: 'Martina Salazar', gender: 'Femenino', nationality: 'Chileno', contact: '345678901', address: 'Av. Providencia 789, Santiago', stage: 'stage4' },
      { id: 19, dni: '86735821', name: 'Nicolás Morales', gender: 'Masculino', nationality: 'Chileno', contact: '765432109', address: 'Av. Providencia 789, Santiago', stage: 'stage5' },
      { id: 20, dni: '70779886', name: 'Ana María López', gender: 'Masculino', nationality: 'Chilena', contact: '345678901', address: 'Paseo Ahumada 123, Santiago', stage: 'stage1' },
      { id: 21, dni: '74150483', name: 'Valentina Vargas', gender: 'Femenino', nationality: 'Chileno', contact: '123456789', address: 'Calle El Roble 123, Antofagasta', stage: 'stage2' },
      { id: 22, dni: '02472918', name: 'Juan Pérez', gender: 'Femenino', nationality: 'Chilena', contact: '345678901', address: 'Av. Providencia 789, Santiago', stage: 'stage3' },
      { id: 23, dni: '24069982', name: 'Claudia Rojas', gender: 'Femenino', nationality: 'Chileno', contact: '345678901', address: 'Calle El Roble 123, Antofagasta', stage: 'stage4' },
      { id: 24, dni: '81291315', name: 'Martina Salazar', gender: 'Femenino', nationality: 'Chilena', contact: '876543210', address: 'Paseo Ahumada 123, Santiago', stage: 'stage5' },
      { id: 25, dni: '61042592', name: 'Juan Pérez', gender: 'Femenino', nationality: 'Chilena', contact: '345678901', address: 'Paseo Ahumada 123, Santiago', stage: 'stage1' },
      { id: 26, dni: '82858340', name: 'Luis Díaz', gender: 'Masculino', nationality: 'Chilena', contact: '765432109', address: 'Calle Las Flores 456, Concepción', stage: 'stage2' },
      { id: 27, dni: '26033067', name: 'José Silva', gender: 'Femenino', nationality: 'Chilena', contact: '345678901', address: 'Av. Libertador 1234, Santiago', stage: 'stage3' },
      { id: 28, dni: '15845793', name: 'Sebastián Reyes', gender: 'Femenino', nationality: 'Chilena', contact: '123456789', address: 'Paseo Ahumada 123, Santiago', stage: 'stage4' },
      { id: 29, dni: '02008235', name: 'María Fernández', gender: 'Masculino', nationality: 'Chileno', contact: '765432109', address: 'Av. Libertador 1234, Santiago', stage: 'stage5' },
    ];        

    return new Promise((resolve) => {
      commit('setItems', rows)
      resolve(rows)
    })

    /*
    const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          const { rows, resume } = response.data

          commit('setItems', rows)
          commit('setResume', resume)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
}
