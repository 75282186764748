export default {
  setItems: (state, data) => {
    state.items.splice(0)
    
    // eslint-disable-next-line array-callback-return
    data.map(x => {
      state.items.push({
        id: x.id,
        dni: x.dni,
        name: x.name,
        gender: x.gender,
        nationality: x.nationality,
        contact: x.contact,
        address: x.address,
        stage: x.stage,
      })
    })
  },
}
