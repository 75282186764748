//import axios from 'axios'

export default {
  // eslint-disable-next-line no-unused-vars
  create: (context, payload) => {
    return new Promise((resolve) => {
      resolve()
    })
    /*
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          // commit('setItem', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  edit: (context, payload) => {
    return new Promise((resolve) => {
      resolve()
    })
    /*
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          // commit('setItem', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  list: (context, payload) => {
    const { commit } = context

    const rows = [
      { id: 1, name: 'Carta de consentimiento', status: 'complete', type: 'download', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 2, name: 'Carte de asentimientos', status: 'pending', type: 'download', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 'x', name: 'Registro de intervención', type:'form', status: 'pending', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 'ficha_ingreso', name: 'Ficha ingreso', type:'form', status: 'pending', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 4, name: 'Carta de consentimiento', status: 'pending', type: 'upload', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 5, name: 'Carta de asentimientos', status: 'pending', type: 'upload', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 'acta_reunion', name: 'Acta reunión', status: 'complete', type: 'form', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 'pauta_egreso', name: 'Pauta egreso', type:'form', status: 'pending', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 'intervencion_aft', name: 'Registro de interveción modalidad AFT', type:'form', status: 'pending', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    ];        

    return new Promise((resolve) => {
      commit('setItems', rows)
      resolve(rows)
    })

    /*acta_reunion
    const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          const { rows, resume } = response.data

          commit('setItems', rows)
          commit('setResume', resume)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
}
