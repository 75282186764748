import { render, staticRenderFns } from "./FlatLayout.vue?vue&type=template&id=327758ef&scoped=true"
import script from "./FlatLayout.vue?vue&type=script&lang=js"
export * from "./FlatLayout.vue?vue&type=script&lang=js"
import style0 from "./FlatLayout.vue?vue&type=style&index=0&id=327758ef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327758ef",
  null
  
)

export default component.exports