export default {
  item: {
    name: null,
    description: null,
    creationDate: null,
    items: [],
  },
  
  editables: [],
}
