export default {
  setItems: (state, data) => {
    state.items.splice(0);
    data.map((x) => {
      state.items.push(x);
    });
  },
  setItem(state, data) {
    state.item.name = data.name;
    state.item.description = data.description;
    state.item.creationDate = data.creationDate;

    state.item.items.splice(0);
    data.fields.map((x) => {
      state.item.items.push(x);
    });
  },
  setEditable(state, data) {
    const extractEditableFields = (fields) => {
      let editableFields = [];

      fields.forEach((field) => {
        const editableTypes = [
          "textfield",
          "datefield",
          "timefield",
          "textarea",
          "select",
          "checkbox",
          "checkbox-group",
          "table"
        ];

        if (editableTypes.includes(field.type)) {
          // Añade los campos editables directos
          editableFields.push(field);
        } else if (field.type === "row" && Array.isArray(field.cols)) {
          // Si el campo es un row, busca dentro de sus columnas
          field.cols.forEach((col) => {
            editableFields = editableFields.concat(
              extractEditableFields([col.value])
            );
          });
        } else if (field.type === "card" && Array.isArray(field.contents)) {
          // Si el campo es un card, busca dentro de sus contenidos
          editableFields = editableFields.concat(
            extractEditableFields(field.contents)
          );
        }
      });

      return editableFields;
    };

    const editableFields = extractEditableFields(data);

    state.editables.splice(0);
    state.editables.push(...editableFields);
  },
  setValue(state, { id, value, name, parent }) {
    
    if (parent) {
      const fieldIndex = state.editables
        .findIndex((field) => field.id === parent);

      const { items } = state.editables[fieldIndex];
      const item = items.find(x => x.id === id);

      item[name] = value;
    } else {
      const fieldIndex = state.editables
        .findIndex((field) => field.id === id);

      if (fieldIndex !== -1) {
        state.editables[fieldIndex].value = value;
      } else {
        console.warn('field index not found', id);
      }
    }
  },
  addRow(state, { id, row }) {
    const tableIndex = state.editables
      .findIndex((field) => field.id === id);

    if (tableIndex !== -1) {
      const table = state.editables[tableIndex];
      const items = table.items;
      const nextIndex = items.length + 1;
      const newRow = {
        id: 'table-' + id + '-' + nextIndex,
        ...row
      };

      console.log(table);

      items.push(newRow);
    } else {
      throw new Error('table index not found');
    }
  },
  removeRow(state, { id, row }) {
    const tableIndex = state.editables
      .findIndex((field) => field.id === id);

    if (tableIndex !== -1) {
      const table = state.editables[tableIndex];
      const items = table.items;
      const index = items.findIndex(x => x.id === row);

      if (index !== -1) {
        items.splice(index, 1);
      }
    } else {
      throw new Error('table index not found');
    }
  },
}
