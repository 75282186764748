import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from '../apps/auth/routes'
import projectRoutes from '../apps/project/routes'
import caseRoutes from '../apps/case/routes'
import engineRoutes from '../apps/engine/routes'
import stageRoutes from '../apps/stage/routes'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "apps-auth" */ '@/apps/auth/AuthApp.vue'),
    children: [...authRoutes],
  },
  {
    path: '/project',
    component: () => import(/* webpackChunkName: "apps-project" */ '@/apps/project/ProjectApp.vue'),
    children: [...projectRoutes],
  },
  {
    path: '/case',
    component: () => import(/* webpackChunkName: "apps-case" */ '@/apps/case/CaseApp.vue'),
    children: [...caseRoutes],
  },
  {
    path: '/engine',
    component: () => import(/* webpackChunkName: "apps-engine" */ '@/apps/engine/EngineApp.vue'),
    children: [...engineRoutes],
  },
  {
    path: '/stage',
    component: () => import(/* webpackChunkName: "apps-stage" */ '@/apps/stage/StageApp.vue'),
    children: [...stageRoutes],
  },
  {
    path: '/',
    redirect: '/auth/login'  // Redirección por defecto
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
