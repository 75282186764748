export default {
  setItems: (state, data) => {
    state.items.splice(0)
    
    // eslint-disable-next-line array-callback-return
    data.map(x => {
      state.items.push({
        id: x.id,
        type: x.type,
        status: x.status,
        name: x.name,
        description: x.description,
      })
    })
  },
}
