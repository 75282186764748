export default [
  {
    path: '',
    name: 'apps-case-list',
    component: () => import(/* webpackChunkName: "apps-case-list" */ '@/apps/case/pages/CaseListPage.vue'),
    meta: {
      layout: 'content',
    },
  },
]
