import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '../apps/auth/store'
import Project from '../apps/project/store'
import Case from '../apps/case/store'
import Stage from '../apps/stage/store'
import Task from '../apps/task/store'
import Engine from '../apps/engine/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: Auth,
    project: Project,
    case: Case,
    stage: Stage,
    task: Task,
    engine: Engine,
  },
})
