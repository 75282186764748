<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <!-- Layout component -->
    <component
      :is="currentLayout"
      v-if="isRouterLoaded"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </component>
  </v-app>
</template>

<script>
import contentLayout from './layouts/DefaultLayout.vue'
import flatLayout from './layouts/FlatLayout.vue'
// import fullContentLayout from './layouts/FullDefaultLayout.vue'
import blankLayout from './layouts/BlankLayout.vue'
import horizontalLayout from './layouts/HorizontalLayout.vue'

export default {
  name: 'App',

  components: {
    contentLayout,
    // fullContentLayout,
    flatLayout,
    horizontalLayout,
    blankLayout,
  },

  computed: {
    isRouterLoaded() {
      if (this.$route.name !== null) return true

      return false
    },
    currentLayout() {
      const layout = this.$route.meta.layout || 'default'

      return `${layout}Layout`
    },
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
  },
}
</script>
