export default [
  {
    path: 'login',
    name: 'apps-auth-login',
    component: () => import(/* webpackChunkName: "apps-auth-login" */ '@/apps/auth/pages/LoginPage.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: 'logout',
    name: 'apps-auth-logout',
    component: () => import(/* webpackChunkName: "apps-auth-logout" */ '@/apps/auth/pages/LogoutPage.vue'),
    meta: {
      layout: 'blank',
    },
  },
]
