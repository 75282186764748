export default {
  setUser: (state, data) => {
    const {
      accessToken,
      userMenu,
      userData,
    } = data

    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('userMenu', JSON.stringify(userMenu))
    localStorage.setItem('userData', JSON.stringify(userData))
  },
}
