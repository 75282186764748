export default [
  {
    path: ':case/:step?',
    name: 'apps-stage-list',
    component: () => import(/* webpackChunkName: "apps-stage-list" */ '@/apps/stage/pages/StageListPage.vue'),
    meta: {
      layout: 'content',
    },
  },
]
