<template>
  <div>
    <v-main>
      <v-container
        class="mt-10"
        fluid
      >
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
