<template>
  <div>
    <v-app-bar
      elevation="0"
      color="primary"
      app
    >
      <v-img
        src="@/assets/logo.png"
        max-height="56px"
        max-width="100px"
        contain
      />
      <v-spacer></v-spacer>

      <!--
      <v-btn
        dark
        text
        :to="{ name: 'apps-auth-register', params: { type: 'customer' } }"
      >
        Transporta tu carga con logsmart
      </v-btn>

      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="yellow"
            v-bind="attrs"
            dark
            v-on="on"
          >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            :to="{ name: 'apps-auth-hub' }"
          >
            <v-list-item-title>
              Regístrate
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="{ name: 'apps-auth-login' }"
          >
            <v-list-item-title>
              Login
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            :to="{ name: 'apps-auth-register', params: { type: 'customer' } }"
          >
            <v-list-item-title>
              Transporta tu carga con Logsmart
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="{ name: 'apps-auth-register', params: { type: 'carrier' } }"
          >
            <v-list-item-title>
              Genera ganancias con Logsmart
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      -->
    </v-app-bar>
    <v-main>
      <v-container
        class="mt-5"
        fluid
      >
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
