// import axios from 'axios'

export default {
  // eslint-disable-next-line no-unused-vars
  create: (context, payload) => {
    return new Promise((resolve) => {
      resolve()
    })
    /*
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          // commit('setItem', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  edit: (context, payload) => {
    return new Promise((resolve) => {
      resolve()
    })
    /*
    // const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(response => {
          const { data } = response

          // commit('setItem', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  archive: (context, payload) => {
    // const { commit } = context

    return new Promise((resolve) => {
      resolve()
    })

    /*
    const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          const { rows, resume } = response.data

          commit('setItems', rows)
          commit('setResume', resume)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
  // eslint-disable-next-line no-unused-vars
  list: (context, payload) => {
    const { commit } = context

    const rows = [
      { id: 0, code: 'PRJ01', name: 'Taller de Música para Niños', status: 'Archivado', manager: { name: 'Javier Pinto', avatar: 'https://i.pravatar.cc/150?img=1', email: 'javier.pinto@example.com' }, start: new Date(2024, 0, 24), end: new Date(2024, 5, 10), progress: 60, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Un taller musical para niños de diversas edades.', organization: 'Colegio' },
      { id: 1, code: 'PRJ02', name: 'Asesoría Psicológica Familiar', status: 'Cerrado', manager: { name: 'Valentina Vargas', avatar: 'https://i.pravatar.cc/150?img=2', email: 'valentina.vargas@example.com' }, start: new Date(2024, 0, 14), end: new Date(2024, 5, 5), progress: 36, address: 'Av. Providencia 789, Santiago', type: 'AFT', description: 'Asesoría psicológica para familias en situación de vulnerabilidad.', organization: 'Municipalidad' },
      { id: 2, code: 'PRJ03', name: 'Programas de Apoyo Familiar', status: 'Archivado', manager: { name: 'Carlos Gómez', avatar: 'https://i.pravatar.cc/150?img=3', email: 'carlos.gomez@example.com' }, start: new Date(2024, 0, 5), end: new Date(2024, 5, 9), progress: 52, address: 'Calle El Roble 123, Antofagasta', type: 'AFT', description: 'Programas diseñados para brindar apoyo a las familias.', organization: 'Centro de Rehabilitación' },
      { id: 3, code: 'PRJ04', name: 'Programas de Nutrición Infantil', status: 'Abierto', manager: { name: 'Carlos Gómez', avatar: 'https://i.pravatar.cc/150?img=3', email: 'carlos.gomez@example.com' }, start: new Date(2024, 0, 11), end: new Date(2024, 5, 25), progress: 66, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Iniciativa para mejorar la nutrición de los niños.', organization: 'Colegio' },
      { id: 4, code: 'PRJ05', name: 'Educación para Todos', status: 'Abierto', manager: { name: 'Diego Torres', avatar: 'https://i.pravatar.cc/150?img=4', email: 'diego.torres@example.com' }, start: new Date(2024, 0, 3), end: new Date(2024, 5, 27), progress: 45, address: 'Calle El Roble 123, Antofagasta', type: 'AFT', description: 'Programa educativo inclusivo para todos los niños.', organization: 'Municipalidad' },
      { id: 5, code: 'PRJ06', name: 'Educación para Todos', status: 'Abierto', manager: { name: 'María Fernández', avatar: 'https://i.pravatar.cc/150?img=5', email: 'maria.fernandez@example.com' }, start: new Date(2024, 0, 26), end: new Date(2024, 5, 2), progress: 23, address: 'Paseo Ahumada 123, Santiago', type: 'AFT', description: 'Fomento de la educación para niños de sectores vulnerables.', organization: 'Colegio' },
      { id: 6, code: 'PRJ07', name: 'Escuela de Padres', status: 'Abierto', manager: { name: 'Javier Pinto', avatar: 'https://i.pravatar.cc/150?img=1', email: 'javier.pinto@example.com' }, start: new Date(2024, 0, 29), end: new Date(2024, 5, 20), progress: 51, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Talleres para padres sobre crianza y educación.', organization: 'Centro de Rehabilitación' },
      { id: 7, code: 'PRJ08', name: 'Deporte para el Desarrollo', status: 'Archivado', manager: { name: 'Martina Salazar', avatar: 'https://i.pravatar.cc/150?img=6', email: 'martina.salazar@example.com' }, start: new Date(2024, 0, 6), end: new Date(2024, 5, 7), progress: 46, address: 'Av. Libertador 1234, Santiago', type: 'AFT', description: 'Actividades deportivas para fomentar habilidades sociales.', organization: 'Municipalidad' },
      { id: 8, code: 'PRJ09', name: 'Cuidado Infantil Integral', status: 'Archivado', manager: { name: 'Gabriela Morales', avatar: 'https://i.pravatar.cc/150?img=7', email: 'gabriela.morales@example.com' }, start: new Date(2024, 0, 1), end: new Date(2024, 5, 10), progress: 6, address: 'Calle El Roble 123, Antofagasta', type: 'AFT', description: 'Cuidado y atención integral para niños.', organization: 'Colegio' },
      { id: 9, code: 'PRJ10', name: 'Fomento a la Lectura', status: 'Abierto', manager: { name: 'Sofía Castro', avatar: 'https://i.pravatar.cc/150?img=8', email: 'sofia.castro@example.com' }, start: new Date(2024, 0, 9), end: new Date(2024, 5, 26), progress: 66, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Promoción de la lectura en niños y jóvenes.', organization: 'Centro de Rehabilitación' },
      { id: 10, code: 'PRJ11', name: 'Desarrollo de Habilidades Sociales', status: 'Abierto', manager: { name: 'Patricia Muñoz', avatar: 'https://i.pravatar.cc/150?img=9', email: 'patricia.munoz@example.com' }, start: new Date(2024, 0, 6), end: new Date(2024, 6, 1), progress: 95, address: 'Av. Providencia 789, Santiago', type: 'AFT', description: 'Fomento de habilidades interpersonales en niños.', organization: 'Municipalidad' },
      { id: 11, code: 'PRJ12', name: 'Cultura y Recreación para Todos', status: 'Cerrado', manager: { name: 'Valentina Vargas', avatar: 'https://i.pravatar.cc/150?img=2', email: 'valentina.vargas@example.com' }, start: new Date(2024, 0, 26), end: new Date(2024, 5, 25), progress: 16, address: 'Calle El Roble 123, Antofagasta', type: 'AFT', description: 'Actividades culturales y recreativas para todos.', organization: 'Colegio' },
      { id: 12, code: 'PRJ13', name: 'Talleres de Superación Personal', status: 'Cerrado', manager: { name: 'Luis Díaz', avatar: 'https://i.pravatar.cc/150?img=10', email: 'luis.diaz@example.com' }, start: new Date(2024, 0, 1), end: new Date(2024, 5, 14), progress: 19, address: 'Paseo Ahumada 123, Santiago', type: 'AFT', description: 'Talleres de desarrollo personal y autoconocimiento.', organization: 'Centro de Rehabilitación' },
      { id: 13, code: 'PRJ14', name: 'Fomento a la Lectura', status: 'Archivado', manager: { name: 'Juan Pérez', avatar: 'https://i.pravatar.cc/150?img=11', email: 'juan.perez@example.com' }, start: new Date(2024, 0, 26), end: new Date(2024, 5, 18), progress: 4, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Iniciativas para aumentar la alfabetización.', organization: 'Municipalidad' },
      { id: 14, code: 'PRJ15', name: 'Convivencia Escolar', status: 'Abierto', manager: { name: 'Juan Pérez', avatar: 'https://i.pravatar.cc/150?img=11', email: 'juan.perez@example.com' }, start: new Date(2024, 0, 15), end: new Date(2024, 5, 20), progress: 17, address: 'Av. Libertador 1234, Santiago', type: 'AFT', description: 'Programas para mejorar la convivencia en las escuelas.', organization: 'Colegio' },
      { id: 15, code: 'PRJ16', name: 'Programas de Apoyo Familiar', status: 'Abierto', manager: { name: 'Nicolás Morales', avatar: 'https://i.pravatar.cc/150?img=12', email: 'nicolas.morales@example.com' }, start: new Date(2024, 0, 20), end: new Date(2024, 5, 24), progress: 66, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Apoyo integral para familias en riesgo.', organization: 'Centro de Rehabilitación' },
      { id: 16, code: 'PRJ17', name: 'Taller de Teatro para Adolescentes', status: 'Abierto', manager: { name: 'José Silva', avatar: 'https://i.pravatar.cc/150?img=13', email: 'jose.silva@example.com' }, start: new Date(2024, 0, 18), end: new Date(2024, 5, 4), progress: 51, address: 'Paseo Ahumada 123, Santiago', type: 'AFT', description: 'Talleres de teatro para el desarrollo de habilidades creativas.', organization: 'Municipalidad' },
      { id: 17, code: 'PRJ18', name: 'Escuela de Padres', status: 'Abierto', manager: { name: 'Carlos Gómez', avatar: 'https://i.pravatar.cc/150?img=3', email: 'carlos.gomez@example.com' }, start: new Date(2024, 0, 24), end: new Date(2024, 5, 14), progress: 92, address: 'Calle El Roble 123, Antofagasta', type: 'AFT', description: 'Talleres educativos para padres sobre crianza.', organization: 'Colegio' },
      { id: 18, code: 'PRJ19', name: 'Fomento a la Lectura', status: 'Abierto', manager: { name: 'Patricia Muñoz', avatar: 'https://i.pravatar.cc/150?img=9', email: 'patricia.munoz@example.com' }, start: new Date(2024, 0, 10), end: new Date(2024, 5, 13), progress: 53, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Iniciativas de lectura para niños y adolescentes.', organization: 'Centro de Rehabilitación' },
      { id: 19, code: 'PRJ20', name: 'Fomento a la Lectura', status: 'Cerrado', manager: { name: 'Valentina Vargas', avatar: 'https://i.pravatar.cc/150?img=2', email: 'valentina.vargas@example.com' }, start: new Date(2024, 0, 14), end: new Date(2024, 5, 14), progress: 73, address: 'Calle El Roble 123, Antofagasta', type: 'AFT', description: 'Proyectos para fomentar la lectura en escuelas.', organization: 'Municipalidad' },
      { id: 20, code: 'PRJ21', name: 'Taller de Teatro para Adolescentes', status: 'Cerrado', manager: { name: 'Luis Díaz', avatar: 'https://i.pravatar.cc/150?img=10', email: 'luis.diaz@example.com' }, start: new Date(2024, 0, 6), end: new Date(2024, 5, 29), progress: 87, address: 'Av. Providencia 789, Santiago', type: 'AFT', description: 'Talleres de teatro para jóvenes.', organization: 'Centro de Rehabilitación' },
      { id: 21, code: 'PRJ22', name: 'Educación para Todos', status: 'Archivado', manager: { name: 'Juan Pérez', avatar: 'https://i.pravatar.cc/150?img=11', email: 'juan.perez@example.com' }, start: new Date(2024, 0, 1), end: new Date(2024, 5, 4), progress: 73, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Iniciativas educativas para todos los niños.', organization: 'Municipalidad' },
      { id: 22, code: 'PRJ23', name: 'Talleres de Arte para Niños', status: 'Cerrado', manager: { name: 'Javier Pinto', avatar: 'https://i.pravatar.cc/150?img=1', email: 'javier.pinto@example.com' }, start: new Date(2024, 0, 2), end: new Date(2024, 5, 10), progress: 54, address: 'Paseo Ahumada 123, Santiago', type: 'AFT', description: 'Talleres artísticos para niños.', organization: 'Colegio' },
      { id: 23, code: 'PRJ24', name: 'Talleres de Arte para Niños', status: 'Cerrado', manager: { name: 'Nicolás Morales', avatar: 'https://i.pravatar.cc/150?img=12', email: 'nicolas.morales@example.com' }, start: new Date(2024, 0, 15), end: new Date(2024, 5, 12), progress: 10, address: 'Calle El Roble 123, Antofagasta', type: 'AFT', description: 'Programas de arte para fomentar la creatividad.', organization: 'Centro de Rehabilitación' },
      { id: 24, code: 'PRJ25', name: 'Programas de Apoyo Familiar', status: 'Abierto', manager: { name: 'Martina Salazar', avatar: 'https://i.pravatar.cc/150?img=6', email: 'martina.salazar@example.com' }, start: new Date(2024, 0, 24), end: new Date(2024, 5, 1), progress: 5, address: 'Av. Libertador 1234, Santiago', type: 'AFT', description: 'Programas para apoyar a familias en crisis.', organization: 'Municipalidad' },
      { id: 25, code: 'PRJ26', name: 'Talleres de Arte para Niños', status: 'Abierto', manager: { name: 'Carlos Gómez', avatar: 'https://i.pravatar.cc/150?img=3', email: 'carlos.gomez@example.com' }, start: new Date(2024, 0, 2), end: new Date(2024, 5, 1), progress: 38, address: 'Paseo Ahumada 123, Santiago', type: 'AFT', description: 'Talleres de arte para niños en riesgo.', organization: 'Colegio' },
      { id: 26, code: 'PRJ27', name: 'Taller de Música para Niños', status: 'Archivado', manager: { name: 'Camila Fuentes', avatar: 'https://i.pravatar.cc/150?img=15', email: 'camila.fuentes@example.com' }, start: new Date(2024, 0, 26), end: new Date(2024, 5, 26), progress: 28, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Clases de música para niños.', organization: 'Centro de Rehabilitación' },
      { id: 27, code: 'PRJ28', name: 'Apoyo Escolar', status: 'Archivado', manager: { name: 'Claudia Rojas', avatar: 'https://i.pravatar.cc/150?img=14', email: 'claudia.rojas@example.com' }, start: new Date(2024, 0, 23), end: new Date(2024, 5, 12), progress: 77, address: 'Calle El Roble 123, Antofagasta', type: 'AFT', description: 'Apoyo académico para estudiantes.', organization: 'Municipalidad' },
      { id: 28, code: 'PRJ29', name: 'Apoyo Psicológico Infantil', status: 'Cerrado', manager: { name: 'Diego Torres', avatar: 'https://i.pravatar.cc/150?img=4', email: 'diego.torres@example.com' }, start: new Date(2024, 0, 26), end: new Date(2024, 5, 17), progress: 64, address: 'Calle Las Flores 456, Concepción', type: 'AFT', description: 'Asesoría psicológica para niños en riesgo.', organization: 'Centro de Rehabilitación' },
      { id: 29, code: 'PRJ30', name: 'Becas para Adolescentes', status: 'Cerrado', manager: { name: 'Sofía Castro', avatar: 'https://i.pravatar.cc/150?img=8', email: 'sofia.castro@example.com' }, start: new Date(2024, 0, 25), end: new Date(2024, 5, 21), progress: 18, address: 'Av. Providencia 789, Santiago', type: 'AFT', description: 'Becas educativas para adolescentes.', organization: 'Municipalidad' },
    ];    
    
    const managers = [
      { name: 'Javier Pinto', avatar: 'https://i.pravatar.cc/150?img=1', email: 'javier.pinto@example.com' },
      { name: 'Valentina Vargas', avatar: 'https://i.pravatar.cc/150?img=2', email: 'valentina.vargas@example.com' },
      { name: 'Carlos Gómez', avatar: 'https://i.pravatar.cc/150?img=3', email: 'carlos.gomez@example.com' },
      { name: 'Diego Torres', avatar: 'https://i.pravatar.cc/150?img=4', email: 'diego.torres@example.com' },
      { name: 'María Fernández', avatar: 'https://i.pravatar.cc/150?img=5', email: 'maria.fernandez@example.com' },
      { name: 'Martina Salazar', avatar: 'https://i.pravatar.cc/150?img=6', email: 'martina.salazar@example.com' },
      { name: 'Gabriela Morales', avatar: 'https://i.pravatar.cc/150?img=7', email: 'gabriela.morales@example.com' },
      { name: 'Sofía Castro', avatar: 'https://i.pravatar.cc/150?img=8', email: 'sofia.castro@example.com' },
      { name: 'Patricia Muñoz', avatar: 'https://i.pravatar.cc/150?img=9', email: 'patricia.munoz@example.com' },
      { name: 'Luis Díaz', avatar: 'https://i.pravatar.cc/150?img=10', email: 'luis.diaz@example.com' },
      { name: 'Juan Pérez', avatar: 'https://i.pravatar.cc/150?img=11', email: 'juan.perez@example.com' },
      { name: 'Nicolás Morales', avatar: 'https://i.pravatar.cc/150?img=12', email: 'nicolas.morales@example.com' },
      { name: 'Claudia Rojas', avatar: 'https://i.pravatar.cc/150?img=13', email: 'claudia.rojas@example.com' },
      { name: 'José Silva', avatar: 'https://i.pravatar.cc/150?img=14', email: 'jose.silva@example.com' },
      { name: 'Camila Fuentes', avatar: 'https://i.pravatar.cc/150?img=15', email: 'camila.fuentes@example.com' },
    ];
    

    return new Promise((resolve) => {
      commit('setItems', rows)
      commit('setManagers', managers)
      resolve(rows)
    })

    /*
    const { commit } = context
    const url = `${process.env.VUE_APP_BASE_URL}/bid`

    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          const { rows, resume } = response.data

          commit('setItems', rows)
          commit('setResume', resume)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
    */
  },
}
